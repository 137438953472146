export const SubjectEnum = {
  CHINESE: 'CHINESE',
  MATH: 'MATH',
  ENGLISH: 'ENGLISH',
};
export const SubjectEnumText = {
  [SubjectEnum.CHINESE]: '中文',
  [SubjectEnum.MATH]: '数学',
  [SubjectEnum.ENGLISH]: '英文',
};

export const TaskStudentDepthServiceEnum = {
  S: 'S',
  A: 'A',
  B: 'B',
  // "C": "C"
};

export const TaskStudentDepthServiceEnumText = {
  [TaskStudentDepthServiceEnum.S]: 'S级',
  [TaskStudentDepthServiceEnum.A]: 'A级',
  [TaskStudentDepthServiceEnum.B]: 'B级',
  // [TaskStudentDepthServiceEnum.C]: "C级"
};

export const ScheduleStatusEnum = {
  PRE_SCHEDULING: 'PRE_SCHEDULING',
  NORMAL: 'NORMAL',
  COMPLETED: 'COMPLETED',
  CANCELLED: 'CANCELLED',
};

export const ScheduleStatusEnumText = {
  [ScheduleStatusEnum.PRE_SCHEDULING]: '预排课',
  [ScheduleStatusEnum.NORMAL]: '待开课',
  [ScheduleStatusEnum.COMPLETED]: '已完成',
  [ScheduleStatusEnum.CANCELLED]: '已取消',
};

export const ClassTypeEnum = {
  PRIVATE: 'PRIVATE',
  GROUP: 'GROUP',
};

export const ClassTypeEnumText = {
  [ClassTypeEnum.PRIVATE]: '私教课',
  [ClassTypeEnum.GROUP]: '班课',
};

export const CourseTypeEnum = {
  TRIAL: 'TRIAL',
  STANDARD: 'STANDARD',
  ALL: 'ALL',
  PUBLIC: 'PUBLIC',
};

export const CourseTypeEnumText = {
  [CourseTypeEnum.TRIAL]: '体验课',
  [CourseTypeEnum.STANDARD]: '标准课',
  [CourseTypeEnum.ALL]: '通用课',
  [CourseTypeEnum.PUBLIC]: '公开课',
};

export const WeekEnumText = {
  1: '周一',
  2: '周二',
  3: '周三',
  4: '周四',
  5: '周五',
  6: '周六',
  0: '周日',
};

export const ServiceChannelEnum = {
  EMAIL: 'EMAIL',
  WECHAT: 'WECHAT',
};

export const ServiceChannelEnumText = {
  [ServiceChannelEnum.EMAIL]: '邮件',
  [ServiceChannelEnum.WECHAT]: '微信',
};


export const StudentTypeEnum = {
  REGULAR: 'REGULAR',
  SUBSCRIBED: 'SUBSCRIBED',
};

export const StudentTypeEnumText = {
  [StudentTypeEnum.REGULAR]: '常规学员',
  [StudentTypeEnum.SUBSCRIBED]: '订阅学员',
};



export const IssuingTypeEnum = {
  NORMAL_ORDER: 'NORMAL_ORDER',
  URGENT_SCHEDULE_ORDER: 'URGENT_SCHEDULE_ORDER',
  URGENT_SUBSTITUTE_ORDER: 'URGENT_SUBSTITUTE_ORDER',
  NORMAL_SUBSTITUTE_ORDER: 'NORMAL_SUBSTITUTE_ORDER',
  URGENT_SPECIFY_TEACHER_ORDER: 'URGENT_SPECIFY_TEACHER_ORDER',
}

export const IssuingTypeEnumText = {
  [IssuingTypeEnum.NORMAL_ORDER]: "常规排课单",
  [IssuingTypeEnum.URGENT_SCHEDULE_ORDER]: "紧急排课单",
  [IssuingTypeEnum.URGENT_SUBSTITUTE_ORDER]: "紧急代课单",
  [IssuingTypeEnum.NORMAL_SUBSTITUTE_ORDER]: "常规代课单",
  [IssuingTypeEnum.URGENT_SPECIFY_TEACHER_ORDER]: "紧急指定单",
}
